import axios from "axios";
import { setCookie } from "typescript-cookie";
import { authServerAddress, adminUserId } from "../configs/env";
import Cookies from 'js-cookie';
import { encrypt, decrypt } from '../utils/encryption';

const SESSION_COOKIE_NAME = 'oasis_session';
const SESSION_EXPIRY_MINUTES = 30;

export async function loginUser(
  formData,
  setMessage,
  setUser,
  setStatus,
  setValidated,
) {
  const fullPath = `${authServerAddress}/user/login/password/`;
  const userReqBody = {
    email: formData.email,
    password: formData.password,
    admin_user_id: adminUserId,
    group_name: "oasis-users",
    session: true,
  };
  axios
    .post(fullPath, userReqBody)
    .then((response) => {
      console.log(response)
      const body = response.data;
      if (body.succeeded) {
        const userData = {
          userId: body.data.user_id,
          idToken: body.data.id_token,
        };
        console.log(`received userData: ${userData}`);
        setValidated(true);
        setUser(userData);
        
        // Handle "Remember Me" functionality
        if (formData.remainLoggedIn) {
          const encryptedSession = encrypt(JSON.stringify(userData));
          Cookies.set(SESSION_COOKIE_NAME, encryptedSession, { 
            expires: new Date(new Date().getTime() + SESSION_EXPIRY_MINUTES * 60 * 1000),
            sameSite: 'strict'
          });
        }
        
        setMessage(body.message);
        setStatus("success");
        return;
      } else {
        setValidated(false);
        setMessage(body.message + ". Please try again");
        setStatus("danger");
        return;
      }
    })
    .catch((error) => {
      console.log(error);
      setValidated(false);
      setMessage(
        error.response.data.message
          ? error.response.data.message
          : "Something went wrong.",
      );
      setStatus("danger");
      return;
    });
}

export async function registerUser(formData, setMessage, setStatus) {
  const fullPath = `${authServerAddress}/user/create_account/`;
  const unformattedBody = {
    email: formData.email,
    password: formData.password,
    admin_user_id: adminUserId,
    group_name: "oasis-users",
  };
  if (formData.password !== formData.repeatPassword) {
    setMessage("Passwords must match");
  } else {
    axios
      .post(fullPath, unformattedBody)
      .then((response) => {
        const body = response.data;
        console.log(`body = ${body}`);
        if (body.succeeded) {
          setMessage(
            "User registered successfully. You should have received an email to verify the account.",
          );
          setStatus("success");
          return;
        } else {
          setMessage(body.message);
          setStatus("danger");
          return;
        }
      })
      .catch((error) => {
        console.log(error);
        setMessage("Something went wrong.");
        setStatus("danger");
        return;
      });
  }
}

export async function checkExistingSession(setUser) {
  const sessionCookie = Cookies.get(SESSION_COOKIE_NAME);
  if (sessionCookie) {
    try {
      const decryptedSession = decrypt(sessionCookie);
      const userData = JSON.parse(decryptedSession);
      
      // Validate the required user data fields
      if (!userData || !userData.userId || !userData.idToken) {
        console.log('Invalid session data');
        Cookies.remove(SESSION_COOKIE_NAME);
        return false;
      }
      
      // Validate session with backend
      try {
        const response = await axios.post(`${authServerAddress}/user/verify_session/`, {
          user_id: userData.userId,
          id_token: userData.idToken,
          admin_user_id: adminUserId,
          group_name: "oasis-users"
        });
        
        if (response.data.succeeded) {
          console.log("Session valid");
          setUser(userData);
          return true;
        } else {
          console.log('Session invalid or expired');
          Cookies.remove(SESSION_COOKIE_NAME);
          setUser({ userId: "", idToken: "" });
          return false;
        }
      } catch (error) {
        console.log('Error validating session:', error);
        Cookies.remove(SESSION_COOKIE_NAME);
        setUser({ userId: "", idToken: "" });
        return false;
      }
    } catch (error) {
      console.log('Error parsing session cookie:', error);
      Cookies.remove(SESSION_COOKIE_NAME);
      setUser({ userId: "", idToken: "" });
      return false;
    }
  }
  return false;
}

export function clearSession() {
  Cookies.remove(SESSION_COOKIE_NAME);
}
