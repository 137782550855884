import React, { useState, useEffect } from 'react';
import { Carousel, Modal, Button } from 'react-bootstrap';
import { FileCarouselProps } from '../component-types';
import FileViewer from './file-viewer';
import { fileProps } from '../data-types';

const FileCarousel: React.FC<FileCarouselProps> = ({ selectedFile, files, onClose }) => {
  const [index, setIndex] = useState(0);
  const [filteredFiles, setFilteredFiles] = useState<fileProps[]>([]);

  useEffect(() => {
    if (selectedFile) {
      const relevantFiles = files
        .filter(file => file.name === selectedFile)
        .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
      setFilteredFiles(relevantFiles);
      setIndex(0);
    }
  }, [selectedFile, files]);

  if (!selectedFile || filteredFiles.length === 0) {
    return null;
  }

  return (
    <Modal show={true} onHide={onClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{selectedFile}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Carousel
          activeIndex={index}
          onSelect={(selectedIndex) => setIndex(selectedIndex)}
          interval={null}
        >
          {filteredFiles.map((file, idx) => (
            <Carousel.Item key={file.fileId}>
              <FileViewer file={file} />
              <Carousel.Caption>
                <p className="text-light bg-dark p-2 rounded">
                  Version {filteredFiles.length - idx} - {new Date(file.createdAt).toLocaleString()}
                </p>
              </Carousel.Caption>
            </Carousel.Item>
          ))}
        </Carousel>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default FileCarousel;
