import { Button, Modal } from 'react-bootstrap';

interface VerificationSuccessModalProps {
  show: boolean;
  handleClose: () => void;
}

function VerificationSuccessModal({ show, handleClose }: VerificationSuccessModalProps) {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Registration Successful</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p style={{ marginBottom: '1rem', fontWeight: 'bold' }}>
          Thank you for registering! We've sent a verification email to your address. 
          Please check your inbox and click the verification link to activate your account.
        </p>
        <p className="text-muted" style={{ marginBottom: '0.5rem' }}>
          Note: The verification email might take a few minutes to arrive. 
          Don't forget to check your spam folder if you don't see it in your inbox!
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleClose}>
          Got it!
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default VerificationSuccessModal;
