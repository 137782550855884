import React, { useState, useEffect, useCallback } from 'react';
import { Stack } from 'react-bootstrap';
import { FilesDisplayProps } from '../component-types';
import { fileProps } from '../data-types';
import FileGrid from './file-grid';
import FileCarousel from './file-carousel';
import { readFileBase64 } from '../../controllers/data';

const FilesDisplay: React.FC<FilesDisplayProps> = ({ selectedSource, setSelectedSource, user }) => {
  const [files, setFiles] = useState<fileProps[]>([]);
  const [selectedFileName, setSelectedFileName] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const fetchFiles = useCallback(async () => {
    setIsLoading(true);
    try {
      const result = await readFileBase64({
        user_session: {
          user_id: user.userId,
          id_token: user.idToken,
        },
        file_target: {
          source_id: selectedSource.sourceId,
          name: null,
          type: null,
          info: null
        },
        mode: "all_from_source"
      });

      if (result.succeeded) {
        const fetchedFiles = result.data.map((file: any) => ({
          fileId: file.file_id,
          sourceId: file.source_id,
          name: file.name,
          type: file.type,
          info: file.info,
          data: file.data,
          createdAt: new Date(file.created_at),
          createdBy: file.created_by,
          updatedAt: new Date(file.updated_at),
          updatedBy: file.updated_by
        }));
        setFiles(fetchedFiles);
      }
    } catch (error) {
      console.error('Error fetching files:', error);
    }
    setIsLoading(false);
  }, [selectedSource.sourceId, user]);

  useEffect(() => {
    fetchFiles();
  }, [fetchFiles]);

  const handleFileSelect = (fileName: string) => {
    setSelectedFileName(selectedFileName === fileName ? null : fileName);
  };

  if (isLoading) {
    return <div>Loading files...</div>;
  }

  return (
    <Stack gap={3}>
      <FileGrid
        files={files}
        onFileSelect={handleFileSelect}
        selectedFileName={selectedFileName}
      />
      <FileCarousel
        selectedFile={selectedFileName}
        files={files}
        onClose={() => setSelectedFileName(null)}
      />
    </Stack>
  );
};

export default FilesDisplay;
