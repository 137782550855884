import React from 'react';
import { FileViewerProps } from '../component-types';
import { MapContainer, TileLayer, GeoJSON } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

const FileViewer: React.FC<FileViewerProps> = ({ file }) => {
  if (!file.data) {
    return <div>No file data available</div>;
  }

  switch (file.type) {
    case 'image':
      return (
        <img 
          src={`data:image/*;base64,${file.data}`} 
          alt={file.name} 
          style={{ maxWidth: '100%', maxHeight: '500px' }} 
        />
      );

    case 'audio':
      return (
        <audio controls style={{ width: '100%' }}>
          <source src={`data:audio/*;base64,${file.data}`} type="audio/*" />
          Your browser does not support the audio element.
        </audio>
      );

    case 'video':
      return (
        <video controls style={{ maxWidth: '100%', maxHeight: '500px' }}>
          <source src={`data:video/*;base64,${file.data}`} type="video/*" />
          Your browser does not support the video element.
        </video>
      );

    case 'geodata':
      try {
        const geoData = JSON.parse(atob(file.data));
        return (
          <div style={{ height: '500px', width: '100%' }}>
            <MapContainer
              center={[0, 0]}
              zoom={2}
              style={{ height: '100%', width: '100%' }}
            >
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              />
              <GeoJSON data={geoData} />
            </MapContainer>
          </div>
        );
      } catch (error) {
        return <div>Error loading geodata</div>;
      }

    case 'text':
      try {
        const textContent = atob(file.data);
        return (
          <pre style={{ 
            maxHeight: '500px', 
            overflow: 'auto', 
            padding: '1rem',
            backgroundColor: '#f8f9fa',
            borderRadius: '4px'
          }}>
            {textContent}
          </pre>
        );
      } catch (error) {
        return <div>Error loading text file</div>;
      }

    default:
      return <div>Unsupported file type</div>;
  }
};

export default FileViewer;
