import Container from "react-bootstrap/Container";
import AppNavBar from "../components/widgets/nav-bar";
import DataManager from "../components/data-manager/data-manager";
import { UserHomePageProps } from "../components/component-types";
import { useNavigate } from "react-router-dom";
import GeneralSubmitButton from "../components/buttons/general-submit";

const HomePage = ({}: UserHomePageProps) => {
  const navigate = useNavigate();
  return (
    <div>
      <h2>Welcome to Oasis-X</h2>
      <GeneralSubmitButton
        label="Login"
        onClick={() => navigate("/login")}
      />
    </div>
  );
};

export default HomePage;
