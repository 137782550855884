import React, { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import EmailInput from '../inputs/email';
import PasswordInput from '../inputs/password';
import { registerFormDataProps } from '../data-types';
import { registerUser } from '../../controllers/users';

interface RegisterModalProps {
  show: boolean;
  handleClose: () => void;
  onRegistrationSuccess: () => void;
}

function RegisterModal({ show, handleClose, onRegistrationSuccess }: RegisterModalProps) {
  const [formData, setFormData] = useState<registerFormDataProps>({
    email: '',
    password: '',
    repeatPassword: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState<string>('');
  const [status, setStatus] = useState<string>('danger');

  function setFieldValue(key: string, value: any): void {
    setFormData((existingValues) => ({
      ...existingValues,
      [key]: value,
    }));
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      await registerUser(formData, setMessage, setStatus);
      if (status === 'success') {
        handleClose();
        onRegistrationSuccess();
        setFormData({
          email: '',
          password: '',
          repeatPassword: '',
        });
      }
    } catch (error) {
      console.error('Error during registration:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title style={{ fontSize: '2rem' }}>Create Account</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="text-muted mb-4">
          Create a new account to start managing your data collections and sources.
        </p>
        <Form onSubmit={handleSubmit}>
          {message && (
            <div className={`alert alert-${status} mb-3`}>
              {message}
            </div>
          )}
          <EmailInput
            id="register-modal-email-input"
            label="Email"
            style={{ fontSize: '0.9rem', fontWeight: 'bold', marginBottom: '1rem' }}
            fieldName="email"
            value={formData.email}
            required={true}
            placeholder="new user email"
            setFieldValue={setFieldValue}
            colWidth={12}
            readOnly={false}
          />
          <PasswordInput
            id="register-modal-password-input"
            label="Password"
            style={{ fontSize: '0.9rem', fontWeight: 'bold', marginBottom: '1rem' }}
            fieldName="password"
            placeholder="new user password"
            value={formData.password}
            required={true}
            setFieldValue={setFieldValue}
            colWidth={12}
            readOnly={false}
          />
          <PasswordInput
            id="register-modal-repeat-password-input"
            label="Re-Enter Password"
            style={{ fontSize: '0.9rem', fontWeight: 'bold', marginBottom: '1rem' }}
            fieldName="repeatPassword"
            placeholder="new user password"
            value={formData.repeatPassword}
            required={true}
            setFieldValue={setFieldValue}
            colWidth={12}
            readOnly={false}
          />
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="primary"
          type="submit"
          onClick={handleSubmit}
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Creating Account...' : 'Create Account'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default RegisterModal;
