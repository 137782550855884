import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import { useNavigate } from "react-router-dom";
import RegisterModal from "../components/forms/register-modal";
import VerificationSuccessModal from "../components/forms/verification-success-modal";
import InfoButton from "../components/buttons/info";

const RegisterPage = () => {
  const navigate = useNavigate();
  const [showRegisterModal, setShowRegisterModal] = useState(true);
  const [showVerificationModal, setShowVerificationModal] = useState(false);

  const handleRegistrationSuccess = () => {
    setShowVerificationModal(true);
  };

  const handleVerificationModalClose = () => {
    setShowVerificationModal(false);
    navigate("/login");
  };

  return (
    <div>
      <Container className="text-center mt-5">
        <InfoButton
          id="register-account-nav-button"
          readOnly={false}
          label="Back to Login"
          onClick={() => navigate("/login")}
        />
      </Container>

      <RegisterModal
        show={showRegisterModal}
        handleClose={() => navigate("/login")}
        onRegistrationSuccess={handleRegistrationSuccess}
      />

      <VerificationSuccessModal
        show={showVerificationModal}
        handleClose={handleVerificationModalClose}
      />
    </div>
  );
};

export default RegisterPage;