import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import { ColDef } from 'ag-grid-community';
import { FileGridProps } from '../component-types';
import { Button } from 'react-bootstrap';

interface FileGridData {
  name: string;
  type: string;
  versions: number;
  lastUpdated: Date;
  info: string | null;
}

const FileGrid: React.FC<FileGridProps> = ({ files, onFileSelect, selectedFileName }) => {
  const uniqueFileNames = Array.from(new Set(files.map(file => file.name)));
  
  const fileData: FileGridData[] = uniqueFileNames.map(name => {
    const filesWithName = files.filter(f => f.name === name);
    const latestFile = filesWithName.reduce((latest, current) => 
      new Date(current.createdAt) > new Date(latest.createdAt) ? current : latest
    );
    
    return {
      name: name,
      type: latestFile.type,
      versions: filesWithName.length,
      lastUpdated: latestFile.updatedAt,
      info: latestFile.info
    };
  });

  const columnDefs: ColDef<FileGridData>[] = [
    { 
      field: 'name',
      headerName: 'File Name',
      sortable: true,
      filter: true,
      flex: 2
    },
    { 
      field: 'type',
      headerName: 'Type',
      sortable: true,
      filter: true,
      flex: 1
    },
    { 
      field: 'versions',
      headerName: 'Versions',
      sortable: true,
      filter: true,
      flex: 1
    },
    { 
      field: 'lastUpdated',
      headerName: 'Last Updated',
      sortable: true,
      filter: true,
      flex: 2,
      valueFormatter: (params: { value: Date }) => new Date(params.value).toLocaleString()
    },
    {
      field: 'info',
      headerName: 'Info',
      sortable: true,
      filter: true,
      flex: 2
    },
    {
      headerName: 'Actions',
      flex: 1,
      cellRenderer: (params: { data: FileGridData }) => (
        <Button
          variant={params.data.name === selectedFileName ? "secondary" : "primary"}
          size="sm"
          onClick={() => onFileSelect(params.data.name)}
        >
          {params.data.name === selectedFileName ? "Hide" : "View"}
        </Button>
      )
    }
  ];

  return (
    <div className="ag-theme-alpine" style={{ height: 400, width: '100%' }}>
      <AgGridReact<FileGridData>
        rowData={fileData}
        columnDefs={columnDefs}
        pagination={true}
        paginationPageSize={10}
        paginationPageSizeSelector={[10, 20, 50, 100]}
      />
    </div>
  );
};

export default FileGrid;
