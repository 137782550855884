import Container from "react-bootstrap/Container";
import AppNavBar from "../components/widgets/nav-bar";
import DataManager from "../components/data-manager/data-manager";

interface DashboardPageProps {};

const DashboardPage = ({}: DashboardPageProps) => {  
  return (
    <div>
      <AppNavBar />
      <Container
        style={{
          paddingTop: "20px",
        }}
      >
        <DataManager/>
      </Container>
    </div>
  );
};

export default DashboardPage;
