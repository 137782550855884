// Debug configuration for API request/response logging

// Debug mode can be enabled/disabled here
export const DEBUG_MODE = {
    enabled: true,
    logRequests: true,
    logResponses: true,
};

// Debug logging utility functions
export const debugLog = {
    request: (method: string, url: string, body?: any) => {
        if (DEBUG_MODE.enabled && DEBUG_MODE.logRequests) {
            console.group(`🔷 API Request: ${method} ${url}`);
            if (body) {
                console.log('Request Body:', body);
            }
            console.groupEnd();
        }
    },
    
    response: (method: string, url: string, response: any) => {
        if (DEBUG_MODE.enabled && DEBUG_MODE.logResponses) {
            console.group(`🔶 API Response: ${method} ${url}`);
            console.log('Response:', response);
            console.groupEnd();
        }
    },
    
    error: (method: string, url: string, error: any) => {
        if (DEBUG_MODE.enabled) {
            console.group(`❌ API Error: ${method} ${url}`);
            console.error('Error:', error);
            console.groupEnd();
        }
    }
};
